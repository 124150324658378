button {
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 12px 50px;
  font-family: $BigShouldersDisplay;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background-color: $yellow;
  border-radius: 7px;
  box-shadow: 0 3px 6px #00000029;
  &:hover {
    background-color: darken($yellow, 10%);
  }
  &:active {
    background-color: darken($yellow, 20%);
  }
  &[disabled] {
    opacity: .8;
    cursor: default;
  }
}
