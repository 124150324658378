@import '/src/styles/variables.scss';

.header {
  padding: 20px 0;
  background-color: $themePrimary;
  @media (max-width: $breakpoint-small) {
    padding: 10px 0;
  }

  .container {
    @media (min-width: $breakpoint-small) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $breakpoint-small) {
      padding: 0;
    }
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .btn-nav {
      display: none;
      width: 44px;
      height: 44px;
      padding: 0;
      text-indent: -9999px;
      background: url(./hamburger.svg) no-repeat center;
      border: none;
      appearance: none;
      cursor: pointer;
      @media (max-width: $breakpoint-small) {
        display: block;
      }
    }

    .main-logo {
      margin-right: 30px;
      @media (max-width: $breakpoint-tablet) {
        margin-right: 15px;
      }
      @media (max-width: $breakpoint-small) {
        margin-right: 0;
      }

      img {
        display: block;
        max-width: 250px;
        @media (max-width: $breakpoint-small) {
          width: 40vw;
        }
      }
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    z-index: 9;
    @media (max-width: $breakpoint-small) {
      display: none;
      position: absolute;
      width: 100%;
      padding-bottom: 20px;
      background-color: $themePrimary;
      &.active {
        display: block;
      }
    }
    .header-nav-list {
      flex-grow: 1;
      list-style: none;
      @media (min-width: $breakpoint-small) {
        display: flex;
      }
      li {
        padding: 0 3.5vw;
        @media (max-width: $breakpoint-tablet) {
          padding: 0 10px;
        }
        @media (max-width: $breakpoint-small) {
          padding: 20px;
        }
        &:hover {
          button {
            color: $yellow;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.086' height='6.603' viewBox='0 0 11.086 6.603'%3E%3Cpath id='Path_452' data-name='Path 452' d='M0,0,4.482,4.482,8.964,0' transform='translate(1.061 1.061)' fill='none' stroke='%23FFBB00' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E%0A") no-repeat right center;
          }
        }
        ul {
          display: none;
          @media (min-width: $breakpoint-small) {
            position: absolute;
            z-index: 9;
            background-color: rgba(9, 17, 29, .90);
            border: 1px solid #000;
            border-radius: 4px;
          }
          li {
            display: block;
            padding: 20px 15px;
            @media (min-width: $breakpoint-small) {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='158' height='1.448' viewBox='0 0 158 1.448'%3E%3Cpath id='Path_453' data-name='Path 453' d='M158,34.642c-50.761.978-107.2.953-158,0,50.806-.953,107.228-.978,158,0' transform='translate(0 -33.918)' fill='%23fff' opacity='0.328'/%3E%3C/svg%3E%0A") no-repeat center bottom;
            }
            &:last-child {
              background: none;
            }
          }
          &.active {
            display: block;
          }
        }
      }
      a,
      button {
        font-family: $montserrat;
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        &:hover,
        &:focus,
        &.active {
          color: $yellow;
        }
      }
      button {
        padding: 0 17px 0 0;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.086' height='6.603' viewBox='0 0 11.086 6.603'%3E%3Cpath id='Path_452' data-name='Path 452' d='M0,0,4.482,4.482,8.964,0' transform='translate(1.061 1.061)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E%0A") no-repeat right center;
        &:hover,
        &:focus,
        &.selected {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.086' height='6.603' viewBox='0 0 11.086 6.603'%3E%3Cpath id='Path_452' data-name='Path 452' d='M0,0,4.482,4.482,8.964,0' transform='translate(1.061 1.061)' fill='none' stroke='%23FFBB00' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E%0A") no-repeat right center;
        }
      }
    }



    .btn-login {
      @media (max-width: $breakpoint-small) {
        margin-top: 20px;
      }
    }
  }
}
