@import '/src/styles/variables.scss';

.leadership {
  padding-top: 37px;
  .leadership-headline {
    padding-bottom: 10px;
    font-size: 55px;
    text-align: center;
    text-transform: uppercase;
    color: $themePrimary;
    @media (max-width: $breakpoint-mobile) {
      font-size: 40px;
    }
  }
  .leadership-members {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 1160px) {
      display: none;
    }
    .leader {
      display: flex;
      align-items: flex-end;
      margin: 65px 0 65px 70px;
      background-color: $themePrimary;
      clip-path: polygon(0 0, 100% 0%, 100% 90%, 90% 105%, -200% 100%);
      .avatar {
        position: absolute;
        width: 195px;
        height: 360px;
        transform: translate(-50%, 5%);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .leader-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 480px;
        max-height: 380px;
        padding: 42px 49px 40px 122px;
        .name {
          font-family: $montserrat;
          font-size: 35px;
          color: $white;
        }
        .title {
          padding-bottom: 28px;
          font-family: $montserrat;
          font-size: 20px;
          color: $white;
        }
        .bio {
          padding-bottom: 60px;
          ul {
            margin: 0 0 0 15px;
            list-style: disc outside;
            li {
              margin-bottom: 0;
              font-family: $montserrat;
              font-size: 17px;
              line-height: 24px;
              color: $white;
            }
          }
        }
        .linkedin {
          max-width: fit-content;
          padding-right: 30px;
          background: url('../../../img/cta-arrow.svg') no-repeat right 0;
          background-size: 20px;
          font-size: 17px;
          text-decoration: underline;
          color: $darkYellow;
        }
      }
    }
  }
  .leadership-members-mobile{
    @media (min-width: 1161px) {
      display: none;
    }
  }
}