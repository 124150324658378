@import '/src/styles/variables.scss';

.ctas {
  background-size: cover;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 670px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
      min-height: 600px;
      padding-top: 120px;
    }

  }
  .btn-shape {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: $breakpoint-mobile) {
      margin: 0 0 30px 0;
    }
  }
}
