@import '/src/styles/variables.scss';

.page-home {
  .rellax-container {
    position: relative;
    height: 100vh;
    z-index: 2;
  }

  .cta-all-games-mobile {
    position: relative;
    display: block;
    padding: 25px 20px;
    text-decoration: none;
    font-size: 28px;
    font-family: $BigShouldersDisplay;
    text-transform: uppercase;
    color: $yellow;
    background-color: $darkGreen;
    z-index: 1;
    span {
      padding-right: 55px;
      background: url(../../img/arrow-yellow.svg) no-repeat right center;
      background-size: contain 100%;
    }
    @media (min-width: $breakpoint-mobile) {
      display: none;
    }
  }
}
