@import '/src/styles/variables.scss';

.parallax-hero {
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: hidden;
  h1 {
    position: fixed;
    top: 100px;
    width: 100%;
    padding: 0 20px;
    z-index: 2;
    color: #fff;
    text-align: center;
    @media (max-width: $breakpoint-mobile) {
      font-size: 32px;
    }
  }

  .parallax-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    min-height: 800px;
    object-fit: cover;
    z-index: 2;
    &:first-child {
      z-index: 1;
    }
    &:nth-last-child(2) {
      width: auto;
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
    &:last-child {
      left: auto;
      right: 0;
      width: auto;
      @media (max-width: $breakpoint-mobile) {
        right: -10%;
      }
    }
  }
}
