@import '/src/styles/variables.scss';

.hero {
  position: relative;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  @media (max-width: $breakpoint-small) {
    // padding-top: 0;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 90vh;
    z-index: 2;
    @media (max-width: $breakpoint-small) {
      // height: auto;
      // padding: 30px 0;
    }
  }

  p {
    margin-top: 15px;
    font-size: 24px;
    font-weight: 500;
    @media (max-width: $breakpoint-small) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .hero-btn {
    margin: 20px 0 40px 0;
  }


  .background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      z-index: 2;
      background-image: linear-gradient(transparent 0, $themePrimary);
    }
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      &.hero-bg-mobile {
        display: none;
      }
      @media (max-width: $breakpoint-small) {
        &.hero-bg-desktop:not(:only-child) {
          display: none;
        }
        &.hero-bg-mobile {
          display: block;
        }
      }
    }
  }
}
