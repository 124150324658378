@import '/src/styles/variables.scss';

.page-games .game {
  position: relative;
  margin: 45px 0;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  transition: clip-path 500ms;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    clip-path: polygon(50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%, 0 50px);
    .game-img {
      transform: scale(1.1);
    }
    .game-content {
      padding-bottom: 110px;
      .game-details {
        opacity: 1;
      }
    }
  }

  .game-img {
    transition: transform 500ms;
    img {
      display: block;
      width: 100%;
      min-height: 450px;
      object-fit: cover;
      object-position: top;
    }
  }

  .game-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 40px 0 35px 0;
    transition: padding-bottom 500ms;
    text-align: center;
    .game-logo {
      max-width: 400px;
      @media (max-width: $breakpoint-mobile) {
        max-width: 50%;
      }
    }

    .game-details {
      position: absolute;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s;
      p {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
      }
      a {
        padding-right: 25px;
        font-family: $BigShouldersDisplay;
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        background: url(../img/arrow.svg) no-repeat right center;
        background-size: 18px;
      }
    }

    .game-platforms {
      position: absolute;
      bottom: 5px;
      left: 10px;
      .mobile {
        background: url(../img/mobile.svg) no-repeat center;
        display: inline-block;
        width: 30px;
        height: 30px;
      }
      .web {
        background: url(../img/desktop.svg) no-repeat center;
        display: inline-block;
        width: 30px;
        height: 30px;
      }
    }
  }
}
