@import '/src/styles/variables.scss';

.page-games {
  .hero {
    padding-top: 0;
    .background-media::after {
      background-image: url(./img/angled-line.svg);
      height: 16%;
      @media (max-width: $breakpoint-mobile) {
        background-image: url(./img/angled-line-mobile.svg);
        background-size: cover;
      }
    }
    .hero-container {
      transform: translateY(-50px);
      justify-content: center;
      @media (max-width: $breakpoint-tablet) {
        min-height: 395px !important;
      }
      h1 {
        font-size: 80px
      }
    }
  }

  .games {
    padding: 100px 0;
    background-color: #FFFCF5;
    .game {
      .game-content {
        min-height: 177px;
        max-height: auto;
      }
    }
    .games-grid {
      flex-direction: row;
      justify-content: space-between;
      @media (min-width: $breakpoint-tablet) {
        display: flex;
      }
      .game {
        width: 31%;
        margin: 0;
        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          margin-bottom: 45px;
        }
        &:hover {
          .game-content {
            padding: 15px 0 110px 0;
          }
        }
        .game-img {
          img {
            min-height: auto;
          }
        }
        .game-content {
          min-height: auto;
          max-height: 195px;
          transition: padding 500ms;
          .game-details {
            p {
              margin-bottom: 10px;
              padding: 0 27px;
              font-size: 14px;
              line-height: 20px;
              @media (max-width: $breakpoint-tablet) {
                padding: 0 10px;
              }
            }
            a {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}
