@import '/src/styles/variables.scss';

.footer {
  position: relative;
  padding: 50px 0;
  font-size: 14px;
  background-color: $themePrimary;
  @media (max-width: $breakpoint-mobile) {
    padding: 25px 0;
  }
  &,
  a,
  p {
    font-size: 14px;
    color: #fff;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $breakpoint-mobile) {
      display: block;
      text-align: center;
    }
  }

  .mntl-logo-mobile {
    display: none;
    margin-bottom: 35px;
    @media (max-width: $breakpoint-mobile) {
      display: inline-block;
    }
  }


  .col {
    width: 33%;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .connect {
    span {
      font-weight: bold;
      text-transform: uppercase;
    }
    > a {
      display: block;
      margin: 10px 0 15px 0;
    }
    .social-nav {
      display: flex;
      @media (max-width: $breakpoint-mobile) {
        justify-content: center;
      }
      li {
        margin-right: 20px;
      }
    }
  }

  .mntl {
    text-align: center;
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
    .monumental {
      margin-top: 5px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .links {
    @media (min-width: $breakpoint-mobile) {
      text-align: right;
    }
    .nav-list {
      li {
        margin-bottom: 5px;
      }
    }
  }
}
