@import './reset.scss';
@import './variables.scss';
@import './btn.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family:"BigShouldersDisplay";
  src:url('../fonts/BigShouldersDisplay-ExtraBold.ttf') format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

html,
body {
  position: relative;
  font-family: $montserrat;
  font-weight: 400;
  font-style: normal;
  background-color: $white;
  @media (max-width: $breakpoint-mobile) {
    br:not(.always) {
      display: none;
    }
    .wysiwyg {
      br {
        display: block;
      }
    }
  }
}
body {
  max-width: 1920px;
  margin: 0 auto;
  // &::before {
  //   content: '';
  //   pointer-events: none;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 180px;
  //   background-image: linear-gradient(#000C18 0%, transparent 100%);
  // }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $BigShouldersDisplay;
  font-weight: 800;
}
p {
  font-family: $montserrat;
  font-size: 20px;
  line-height: 29px;
}

h1 {
  font-size: 60px;
  line-height: normal;
  @media (max-width: $breakpoint-mobile) {
    font-size: 36px;
  }
}
h2 {
  font-size: 60px;
  @media (max-width: $breakpoint-mobile) {
    font-size: 40px;
  }
}

.wysiwyg {
  p,
  ul {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 25px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 16px;
    }
  }
  a {
    font-weight: bold;
    color: #000;
    word-break: break-all;
  }
  ul {
    list-style: disc inside;
    li {
      margin-bottom: 10px;
    }
  }
}

.container {
  max-width: $container;
  margin: 0 auto;
  padding: 0 20px;
}

.page-outro {
  position: relative;
  background-position: center;
  background-size: cover;
  &::before,
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    width: 100%;
    height: 25%;
    z-index: 2;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
