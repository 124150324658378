@import '/src/styles/variables.scss';

.page-team {
  .hero {
    .background-media {
      &::after {
        display: none;
      }
    }
    .container {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 53%;
        height: 100%;
        background: #184540DE;
        z-index: 1;
        clip-path: polygon(0 0, 65% 0, 90% 100%, 0 100%);
        @media (max-width: $breakpoint-small) {
          width: 100%;
          height: 50%;
          clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
        }
      }
      .hero-container {
        max-width: 35%;
        position: relative;
        @media (max-width: $breakpoint-small) {
          max-width: 100%;
          transform: translateY(-15%);
          min-height: 480px !important;
        }
        @media (min-width: $breakpoint-ultrawide) {
          transform: translateX(-10%);
        }
        h1 {
          text-align: left;
          line-height: 65px;
          @media (max-width: $breakpoint-small) {
            font-size: 40px;
            line-height: 45px;
          }
        }
      }
    }
  }
  .container {
    &.team-content {
      @media (max-width: 1160px) {
        overflow: hidden;
      }
    }
  }
  .team-headline {
    padding: 74px 20%;
    font-size: 20px;
    text-align: center;
    color: #184540;
    @media (max-width: $breakpoint-small) {
      padding: 57px 5%;
      font-size: 17px;
      line-height: 24px;
    }
  }
}