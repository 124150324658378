@import '/src/styles/variables.scss';

.features {
  padding: 70px 0 170px 0;
  overflow: hidden;
  background-size: cover;
  background-position: top;

  .subheading {
    margin-bottom: 150px;
    text-align: center;
    color: #fff;
    .subheading-heading {
      margin-bottom: 20px;
    }
  }

  .feature {
    position: relative;
    width: 80%;
    padding: 50px 30px;
    color: #fff;
    &:not(:last-child) {
      margin-bottom: 250px;
    }
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      clip-path: polygon(7% 0, 1000% 0, 1000% 1000%, -1000% 1000%, 0 50%, 0 5%);
    }

    &:nth-child(odd) {
      margin-left: 20%;
      background-color: #{$themePrimary}CC;
      @media (min-width: $breakpoint-mobile) {
        padding: 60px 80px 60px 25%;
        clip-path: polygon(-1000% -1000%, 100% 0, 100% 90%, 97% 100%, -1000% 1000%);
      }
      @media (max-width: $breakpoint-mobile) {
        margin-left: 0;
      }
      .feature-img {
        transform: translate(-10%, 20%) scale(1.5);
        @media (min-width: $breakpoint-mobile) {
          left: -25%;
          transform: translateY(-50%) scale(1.5);
        }
      }
    }

    &:nth-child(even) {
      margin-left: 0;
      margin-right: 20%;
      color: $themePrimary;
      background-color: #{$themeSecondary}CC;
      @media (min-width: $breakpoint-mobile) {
        padding: 60px 25% 60px 80px;
        clip-path: polygon(3% 0, 1000% -1000%, 1000% 1000%, 0 100%, 0 10%);
      }
      @media (max-width: $breakpoint-mobile) {
        margin-right: 0;
      }
      .feature-img {
        transform: translate(10%, 20%) scale(1.5);
        @media (min-width: $breakpoint-mobile) {
          right: -25%;
          transform: translateY(-50%) scale(1.8);
        }
      }
    }

    .feature-content {
      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 50px;
      }
      h2 {
        margin-bottom: 30px;
      }
    }

    .feature-img {
      @media (min-width: $breakpoint-mobile) {
        position: absolute;
        top: 50%;
        width: 40%;
      }
      img {
        max-width: 100%;
      }
    }
  }
}
