@import '/src/styles/variables.scss';

.leadership-mobile {
  margin-bottom: 80px;
  .slick-slider {
    display: flex;
    flex-direction: column-reverse;
    .slick-list {
      overflow: visible;
      margin-top: 225px;
      .slick-track {
        .slick-slide{
          .leader {
            background-color: $themePrimary;
            display: flex;
            justify-content: center;
            .avatar {
              display: none;
            }
            .leader-info {
              color: $white;
              padding: 65px 40px;
              .name {
                font-family: $montserrat;
                font-size: 22px;
                text-align: center;
              }
              .title {
                font-family: $montserrat;
                font-size: 12px;
                text-align: center;
              }
              .bio {
                padding: 35px 0 21px 0;
                text-align: left;
                ul {
                  margin: 0 0 0 15px;
                  list-style: disc outside;
                  li {
                    margin-bottom: 0;
                    font-family: $montserrat;
                    font-size: 14px;
                    color: $white;
                  }
                }
              }
              .linkedin{
                padding-right: 26px;
                background: url('../../../../img/cta-arrow.svg') no-repeat right -2px;
                background-size: 19px;
                font-family: $montserrat;
                font-size: 14px;
                text-align: left;
                text-decoration: underline;
                color: $darkYellow;
              }
            }
          }
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: calc(50% + 3vw);
      transform: translate(0, -50%);
      width: 40px;
      height: 40px;
      z-index: 1;
      cursor: pointer;
      @media (max-width: 350px) {
        top: calc(50% - 5vw);
      }
      &.slick-left {
        left: 0;
      }
      &.slick-right {
        right: 0;
        img {
          transform: scaleX(-1);
        }
      }
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: baseline;
      top: 80px;
      bottom: auto;
      cursor: pointer;
      @media (max-width: 350px) {
        top: calc(80px + 3vw);
      }
      li {
        width: 100%;
        height: 100%;
        max-width: 95px;
        max-height: 186px;
        margin: 0;
        &:first-child {
          margin-right: -3px;
          z-index: 0;
        }
        &:nth-child(2) {
          margin-left: -7px;
          z-index: 1;
        }
        &:nth-child(3) {
          margin-left: -30px;
          z-index: 2;
        }
        &:last-child {
          margin-left: -37px;
          z-index: 3;
        }
        &.slick-active {
          z-index: 4;
          .leadership-mobile__dot {
            transform: scale(1.3);
            img {
              filter: grayscale(0%);
            }
          }
        }
        .leadership-mobile__dot {
          width: 100%;
          height: 100%;
          &::before {
            content: "";
          }
          img {
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
          }
        }
      }
    }
  }
}