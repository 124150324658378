$container: 1230px;
$breakpoint-small: 743px;
$breakpoint-mobile: 767px;
$breakpoint-tablet: 1024px;
$breakpoint-tablet: 1024px;
$breakpoint-ultrawide: 1700px;

$montserrat: montserrat, sans-serif;
$BigShouldersDisplay: BigShouldersDisplay, sans-serif;

$themePrimary: #184540;
$themeSecondary: #B0D9CD;

$darkbg: #010722;
$white: #FFFCF5;
$darkGreen: #12332F;
$yellow: #E7AF3D;
$darkYellow: #F1AC07;
$deepred: #570101;
