@import '/src/styles/variables.scss';

$animationTiming: 500ms;
@media (min-width: $breakpoint-mobile) {
  .page-home .games {
    display: flex;
    overflow: hidden;
    background-color: $darkGreen;
    &.active {
      .game {
        &:not(.active) {
          &::before {
            opacity: 1;
            left: -75%;
            width: 175%;
          }
          .game-logo-inactive {
            opacity: 1;
          }
          .game-content {
            opacity: 0;
          }
        }
      }
    }

    .games-content {
      display: flex;
      width: 80%;
    }

    .game {
      flex-grow: 1;
      position: relative;
      width: 33%;
      margin: 0;
      transform: skew(15deg);
      transition: width $animationTiming;
      cursor: pointer;
      &:first-child {
        .game-details {
          transform: skew(-15deg) translateX(-15%);
        }
      }
      &:nth-child(2) {
        overflow: hidden;
        border-left: 3px solid $yellow;
        border-right: 3px solid $yellow;
        .game-cta a {
          color: #fff;
          background-image: url(../../../img/arrow-white.svg) ;
        }
      }
      &:last-child {
        overflow: hidden;
        border-right: 3px solid $yellow;
      }
      &:hover {
        width: 45%;
        .game-image {
          transform: skew(-15deg) scale(1.2);
        }
      }
      &.active {
        .game-image {
          opacity: 0;
          pointer-events: none;
        }
        .game-video {
          opacity: 1;
          pointer-events: all;
        }
        .game-details,
        .game-content::before {
          height: 160px;
        }
        .game-image-cta {
          img {
            height: 3.5vw;
            transform: translateX(-50%);
            @media (max-width: 1200px) {
              transform: translateY(-125%);
            }
          }
        }
        .game-cta {
          opacity: 1;
          pointer-events: all;
        }
      }
      &.active {
        width: 80%;
      }
      &::before {
        content: '';
        opacity: 0;
        transition: opacity 200ms, width $animationTiming, left $animationTiming;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 35%;
        width: 25%;
        height: 100%;
        background-color: #184540BF;
        z-index: 2;
      }

      .game-logo-inactive {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 35px;
        z-index: 3;
        transform: skew(-15deg) translate(-50%, -50%);
        transition: opacity $animationTiming;
        pointer-events: none;
      }

      .game-image,
      .game-video {
        position: absolute;
        top: 0;
        left: -50%;
        width: 200%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: skew(-15deg) scale(1.1);
        transition: transform $animationTiming, opacity $animationTiming;
      }
      .game-video {
        opacity: 0;
        pointer-events: none;
        object-fit: cover;
      }

      .game-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 700px;
        transition: opacity $animationTiming;
        &::before {
          content: '';
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: -50%;
          width: 150%;
          height: 120px;
          transition: height $animationTiming;
          background-color: var(--colorScheme);
        }
      }
      .game-details {
        opacity: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        z-index: 1;
        transform: skew(-15deg);
        transition: height $animationTiming;
      }
      .game-image-cta {
        transition: padding $animationTiming;
        img {
          transition: transform $animationTiming, height $animationTiming;
          max-width: 100%;
          height: 4vw;
          max-height: 50px;
        }
      }
      .game-cta {
        position: absolute;
        bottom: 30px;
        left: 55%;
        opacity: 0;
        transition: opacity $animationTiming;
        width: 215px;
        color: #fff;
        pointer-events: none;
        @media (max-width: 1200px) {
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
        p {
          margin-bottom: 10px;
          font-size: 15px;
          line-height: normal;
        }
        a {
          padding-right: 25px;
          font-size: 18px;
          font-family: $BigShouldersDisplay;
          text-transform: uppercase;
          color: $yellow;
          text-decoration: none;
          background: url(../../../img/arrow-yellow.svg) no-repeat right center;
          background-size: 18px;
        }
      }
    }

    .cta-all-games {
      padding: 40px 0;
      text-decoration: none;
      font-family: $BigShouldersDisplay;
      font-size: 44px;
      text-transform: uppercase;
      color: $yellow;
      span {
        padding-right: 60px;
        background: url(../../../img/arrow-yellow.svg) no-repeat right center;
      }
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .page-home .games {
    overflow: hidden;
    background-color: $darkGreen;
    &.active {
      .game {
        &:not(.active) {
          .game-details::before {
            background-color: #184540BF;
          }
        }
      }
    }

    .games-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .game {
      flex-grow: 1;
      position: relative;
      width: 100%;
      margin: 0;
      transform: skew(0, -15deg);
      transition: width $animationTiming;
      cursor: pointer;
      &:nth-child(2) {
        z-index: 2;
        overflow: hidden;
        border-top: 3px solid $yellow;
        border-bottom: 3px solid $yellow;
        .game-cta a {
          color: #fff;
          background-image: url(../../../img/arrow-white.svg) ;
        }
      }
      &:last-child {
        border-bottom: 0;
        .game-details::before {
          height: 200%;
        }
      }
      &.active {
        .game-image {
          opacity: 0;
          pointer-events: none;
        }
        .game-video {
          opacity: 1;
          pointer-events: all;
        }
        .game-content {
          height: 66vh;
          @media (max-height: 700px) {
            height: 85vh;
          }
        }
        .game-image-cta {
          img {
            transform: translateY(0);
          }
        }
        .game-cta {
          opacity: 1;
          pointer-events: all;
        }
        &:last-child {
          .game-details::before {
            height: 150%;
          }
        }
      }

      .game-logo-inactive {
        display: none;
      }

      .game-image,
      .game-video {
        position: absolute;
        top: 0;
        left: -100%;
        width: 250%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: skew(-15deg);
        transition: transform $animationTiming, opacity $animationTiming;
        &,
        &:first-child {
          top: -25%;
          left: 0;
          width: 100%;
          height: 150%;
          transform: skew(0, 15deg);
        }
      }
      .game-video {
        opacity: 0;
        pointer-events: none;
        object-fit: cover;
      }
      .game-content,
      .game-details {
        height: 27vh;
        min-height: 165px;
      }
      .game-content {
        transition: height $animationTiming;
      }
      .game-details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        z-index: 1;
        &::before {
          content: '';
          pointer-events: none;
          position: absolute;
          top: -50%;
          left: 0;
          width: 100%;
          height: 150%;
          transition: height $animationTiming, background-color $animationTiming;
          background-color: var(--colorScheme);
        }
      }
      .game-image-cta {
        opacity: 1;
        transform: skew(0, 15deg);
        transition: opacity $animationTiming;
        img {
          transform: translateY(150%);
          transition: transform $animationTiming;
          max-width: 100%;
          max-height: 35px;
        }
      }
      .game-cta {
        opacity: 0;
        transition: opacity $animationTiming;
        max-width: 215px;
        margin: 10px auto 0 auto;
        color: #fff;
        pointer-events: none;
        p {
          margin-bottom: 5px;
          font-size: 15px;
          line-height: normal;
        }
        a {
          padding-right: 25px;
          font-size: 18px;
          font-family: $BigShouldersDisplay;
          text-transform: uppercase;
          color: $yellow;
          text-decoration: none;
          background: url(../../../img/arrow-yellow.svg) no-repeat right center;
          background-size: 18px;
        }
      }
    }
  } // .games
}
