@import '/src/styles/variables.scss';

$btnAnimationTiming: 400ms;

.btn-shape {
  position: relative;
  display: inline-block;
  padding: 15px 20px;
  font-family: $BigShouldersDisplay;
  font-size: 40px;
  font-weight: 800;
  color: $themePrimary;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: $darkYellow;
  clip-path: polygon(7% 0, 100% 0, 100% 70%, 93% 100%, 0 100%, 0 30%);
  &:hover {
    background-color: darken($darkYellow, 5%);
  }
  @media (max-width: $breakpoint-mobile) {
    font-size: 32px;
  }
}
